// components/Hero.js
import React from 'react';
import { 
  Box, 
  Container, 
  Typography, 
  Button,
  useMediaQuery,
  Divider,
  Card,
  CardContent,
  Grid,
  Link
} from '@mui/material';
import { useTheme } from '@mui/material/styles';

// Icon imports for the cards
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import StorageIcon from '@mui/icons-material/Storage';
import BuildIcon from '@mui/icons-material/Build';
import DataUsageIcon from '@mui/icons-material/DataUsage';
import SettingsIcon from '@mui/icons-material/Settings';
import StorefrontIcon from '@mui/icons-material/Storefront';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import AssessmentIcon from '@mui/icons-material/Assessment';
import EmojiObjectsIcon from '@mui/icons-material/EmojiObjects';

const Hero = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  // Card data for each section
  const challengeCards = [
    {
      title: 'Current Limitations',
      description:
        'AI coding agents can solve basic tasks but struggle with complex challenges.',
      icon: <ErrorOutlineIcon sx={{ color: '#60a5fa' }} fontSize="large" />
    },
    {
      title: 'Data Bottleneck',
      description:
        'Low-quality data hinders performance of these models on harder tasks.',
      icon: <StorageIcon sx={{ color: '#60a5fa' }} fontSize="large" />
    },
    {
      title: 'Complex Challenges',
      description:
        'AI still lacks the human-like insight needed for intricate engineering tasks.',
      icon: <BuildIcon sx={{ color: '#60a5fa' }} fontSize="large" />
    },
  ];

  const solutionCards = [
    {
      title: 'High-Quality Datasets',
      description:
        'We build datasets that capture expert-level reasoning for smarter AI.',
      icon: <DataUsageIcon sx={{ color: '#8b5cf6' }} fontSize="large" />
    },
    {
      title: 'Robust Eval Pipeline',
      description:
        'Our rigorous testing ensures only the best-performing models thrive.',
      icon: <SettingsIcon sx={{ color: '#8b5cf6' }} fontSize="large" />
    },
    {
      title: 'Agora Marketplace',
      description:
        'Engineers can monetize their problem-solving processes on our platform.',
      icon: <StorefrontIcon sx={{ color: '#8b5cf6' }} fontSize="large" />
    },
  ];

  const matterCards = [
    {
      title: 'Data That Delivers',
      description:
        'Access datasets that empower AI with human-level precision.',
      icon: <ThumbUpIcon sx={{ color: '#60a5fa' }} fontSize="large" />
    },
    {
      title: 'Enhanced Evaluation',
      description:
        'Strict model assessments boost performance and innovation.',
      icon: <AssessmentIcon sx={{ color: '#60a5fa' }} fontSize="large" />
    },
    {
      title: 'Pioneering Innovation',
      description:
        'Drive the next wave of autonomous AI in software engineering.',
      icon: <EmojiObjectsIcon sx={{ color: '#60a5fa' }} fontSize="large" />
    },
  ];

  return (
    <Box
      sx={{
        position: 'relative',
        minHeight: '100vh',
        display: 'flex',
        justifyContent: 'center',
        overflow: 'hidden',
        backgroundColor: 'transparent',
        py: 4,
      }}
    >
      <Container maxWidth="md">
        <Box sx={{ p: 4, borderRadius: 2 }}>
          <Box
            component="img"
            src="/icon.png"
            alt="PlayDo AI Icon"
            sx={{
              width: '150px',
              height: 'auto',
              display: 'block',
              mx: 'auto',
              mb: 4,
              filter: 'drop-shadow(0 0 10px rgba(96, 165, 250, 0.5))'
            }}
          />

          <Typography
            variant={isMobile ? 'h5' : 'h4'}
            align="center"
            sx={{
              mb: 4,
              fontWeight: 800,
              background: 'linear-gradient(to right, #60a5fa, #8b5cf6)',
              WebkitBackgroundClip: 'text',
              WebkitTextFillColor: 'transparent',
              width: '100%',
              display: 'block',
            }}
          >
            Build the Future of Software Engineering with Human-Level Code Reasoning
          </Typography>

          <Divider sx={{ my: 4 }} />

          {/* The Challenge Section */}
          <Typography
            variant="h5"
            align="center"
            sx={{ fontWeight: 700, mb: 3 }}
          >
            The Challenge
          </Typography>
          <Grid container spacing={2}>
            {challengeCards.map((card, index) => (
              <Grid item xs={12} sm={4} key={`challenge-${index}`}>
                <Card sx={{ height: '100%', background: 'rgba(96, 165, 250, 0.05)' }}>
                  <CardContent sx={{ textAlign: 'center' }}>
                    {card.icon}
                    <Typography variant="h6" sx={{ fontWeight: 600, mt: 1, mb: 2 }}>
                      {card.title}
                    </Typography>
                    <Typography variant="body2">
                      {card.description}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>

          {/* Our Solution Section */}
          <Typography
            variant="h5"
            align="center"
            sx={{ fontWeight: 700, my: 4 }}
          >
            Our Solution
          </Typography>
          <Grid container spacing={2}>
            {solutionCards.map((card, index) => (
              <Grid item xs={12} sm={4} key={`solution-${index}`}>
                <Card sx={{ height: '100%', background: 'rgba(139, 92, 246, 0.05)' }}>
                  <CardContent sx={{ textAlign: 'center' }}>
                    {card.icon}
                    <Typography variant="h6" sx={{ fontWeight: 600, mt: 1, mb: 2 }}>
                      {card.title}
                    </Typography>
                    <Typography variant="body2">
                      {card.description}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>

          {/* Why It Matters Section */}
          <Typography
            variant="h5"
            align="center"
            sx={{ fontWeight: 700, my: 4 }}
          >
            Why It Matters
          </Typography>
          <Grid container spacing={2}>
            {matterCards.map((card, index) => (
              <Grid item xs={12} sm={4} key={`matter-${index}`}>
                <Card sx={{ height: '100%', background: 'rgba(96, 165, 250, 0.05)' }}>
                  <CardContent sx={{ textAlign: 'center' }}>
                    {card.icon}
                    <Typography variant="h6" sx={{ fontWeight: 600, mt: 1, mb: 2 }}>
                      {card.title}
                    </Typography>
                    <Typography variant="body2">
                      {card.description}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>

          {/* Contact Section */}
          <Box sx={{ textAlign: 'center', mt: 6 }}>
            <Typography variant="body1" sx={{ mb: 3 }}>
              Interested in revolutionizing software engineering? Email{' '}
              <Link href="mailto:jesse@playdo.ai" underline="hover">jesse@playdo.ai</Link>{' '}
              or{' '}
              <Link href="mailto:karn@playdo.ai" underline="hover">karn@playdo.ai</Link> to get started.
            </Typography>
            <Button
              variant="contained"
              size="large"
              href="https://calendly.com/jessechoe10/playdoai"
              sx={{
                background: 'linear-gradient(to right, #60a5fa, #8b5cf6)',
                color: '#fff',
                px: 4,
                py: 2,
                fontWeight: 600,
                borderRadius: '8px',
                textTransform: 'none',
                '&:hover': {
                  backgroundColor: '#60a5fa',
                },
              }}
            >
              Schedule a Call
            </Button>
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

export default Hero;
